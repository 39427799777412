import { render, staticRenderFns } from "./SavedModelStats.vue?vue&type=template&id=50c1298e&scoped=true&"
import script from "./SavedModelStats.vue?vue&type=script&lang=js&"
export * from "./SavedModelStats.vue?vue&type=script&lang=js&"
import style0 from "./SavedModelStats.vue?vue&type=style&index=0&id=50c1298e&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c1298e",
  null
  
)

export default component.exports