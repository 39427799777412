<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 70px">
    <main class="page-content">
      <div class="container-fluid">
        <ul class="breadcrumb ProximaNovaRegular mx-4">
          <li>
            <router-link to="/saved-plans">
              <span>Saved Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/saved/model/results">
              <span>Model</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">
            Sale Statistics
          </li>
        </ul>
      </div>
      <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center">
          <div class="title">Sales-Statistics</div>
          <div class="d-flex">
            <div>
              <div class="" style="height: 40%">
                <div class="w-100">
                  <w-white-dropdown
                    :options="statsDropdown"
                    :placeHolder="'Select'"
                    :labelText="'Model'"
                    :selectedOption="selectedstat"
                    @input="selectOption($event)"
                  ></w-white-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <w-chart-overview :chartSummary="salseStatics"></w-chart-overview>
      </div>

      <div class="container-fluid">
        <div class="title">Actual vs Prediction</div>

        <line-chart
          yAxisTitle="Millions"
          :boxshadow="true"
          :chartData="lineChartData"
        />
      </div>

      <div class="text-right container-fluid">
        <w-button
          :buttonText="'Progress'"
          class="my-5"
          :buttonLoader="'normal'"
          @buttonClicked="nextToTheInsights"
        >
        </w-button>
      </div>
    </main>
  </div>
</template>
<script>
import LineChart from "@/components/Chart/LineChart.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import Button from "@/components/Profile/Button.vue";
import { abbreviateNumber } from "js-abbreviation-number";
import { AIDigitalPoem } from "@/services/AIDigitalPoemServices";
const aiDigitalPoem = new AIDigitalPoem();
export default {
  name: "Stats",
  components: {
    LineChart,
    "w-button": Button,
    "w-white-dropdown": WhiteDropdown,
    "w-chart-overview": ChartOverview,
  },
  data() {
    return {
      selectedstat: "",
      statsDropdown: [
        { text: "Elite", id: "elite" },
        { text: "Pro", id: "pro" },
        { text: "Lite Plan", id: "Lite Plan" },
      ],
      lineChartData: {
        series: [
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 5,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Actual sales",
          },
          {
            data: [],
            marker: {
              symbol: "circle",
              radius: 5,
            },
            type: "spline",
            name: "Predicted sales",
          },
        ],
        title: {
          text: "Test Dataset",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          y: -20,
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginBottom: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        xAxis: {
          title: {
            text: "Weekly",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
          categories: [],
        },
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      salseStatics: [],
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
    };
  },
  methods: {
    selectOption(e) {
      if (e.id === this.selectedstat.id) {
        return;
      }
      this.getModalSummary();
      sessionStorage.setItem("activeSavedModalName", JSON.stringify(e));
      this.selectedstat = e;
      this.getActualChartData();
    },
    nextToTheInsights() {
      this.$router.push("/saved/model/results/attribution");
    },
    getActualChartData() {
      aiDigitalPoem
        .getChartData(
          {
            uniqueId: sessionStorage.getItem("savedRunId"),
            model: this.selectedstat.id,
          },
          "Attribution",
          "ActualVsPred"
        )
        .then((res) => {
          this.lineChartData.series[0].data = [];
          this.lineChartData.series[1].data = [];
          this.lineChartData.xAxis.categories = [];
          // this.lineChartData.xAxis.title = res.response.timeperiod
          for (var i = 0; i < res.response.data.length; i++) {
            this.lineChartData.xAxis.categories.push(res.response.data[i].name);
            this.lineChartData.series[0].data.push(
              res.response.data[i].previous
            );
            this.lineChartData.series[1].data.push(
              res.response.data[i].predicted
            );
          }
        });
    },
    getModalSummary() {
      aiDigitalPoem
        .getAllModals({ uniqueId: sessionStorage.getItem("savedRunId"), model: "" })
        .then((res) => {
          for (var i = 0; i < res.response.values.length; i++) {
            if (this.selectedstat.id === res.response.values[i].title) {
              this.salseStatics = [];
              for (var j = 0; j < res.response.values[i].statics.length; j++) {
                this.salseStatics.push({
                  name: res.response.values[i].statics[j].name,
                  value: this.orgnizeNumber(
                    res.response.values[i].statics[j].value
                  ),
                  className: this.classess[j],
                  image: res.response.values[i].statics[j].name + ".svg",
                });
              }
            }
          }
          sessionStorage.setItem(
            "selectedSavedPlan",
            JSON.stringify(this.salseStatics)
          );
        });
    },
  },
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1);
        } else {
          return Math.round(data * 100) / 100;
        }
      };
    },
  },
  created() {
    this.salseStatics = [];
    this.statsDropdown = [];
    this.statsDropdown = JSON.parse(sessionStorage.getItem("SavedmodalList"));
    var statics = JSON.parse(sessionStorage.getItem("selectedSavedPlan"));
    this.selectedstat = JSON.parse(
      sessionStorage.getItem("activeSavedModalName")
    );
    for (var i = 0; i < statics.length; i++) {
      this.salseStatics.push({
        name: statics[i].name,
        value: statics[i].value,
        className: this.classess[i],
        image: statics[i].name + ".svg",
      });
    }
    this.getActualChartData();
  },
};
</script>

<style lang="css" scoped>
.container-stats {
  width: 95%;
  margin: 70px auto 0 auto;
}
.container-statistics {
  padding-top: 1px;
}

.title {
  font-family: ProximaNovaBold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin-top: 48px;
  margin-bottom: 32px;
}

.wrapper-statistics {
  box-shadow: 0px 0px 16px -13px rgb(0 0 0);
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.item {
  display: flex;
  width: 20%;
  position: relative;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
